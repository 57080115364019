'use strict'

angular
	.module('theme.inbox', [])
	.controller('InboxController', ["$scope", "$rootScope", "$global", "$location", "$modal", "MemoDetailService", "NotificationService", "$routeParams", "IpadService", function ($scope, $rootScope, $global, $location, $modal, MemoDetailService, NotificationService, $routeParams, IpadService) {

		$scope.mailBox = 'inbox';
		$scope.pageSize = 15;
		$scope.IsMemoOpen;
		$scope.currentPage = 1;
		$scope.totalMemos = 0;
		$scope.totalSentMemos = 0;
		$scope.totalDeleted = 0;
		$scope.inboxTotalPages = 0;
		$scope.SentboxTotalPages = 0;
		$scope.deletedTotalPages = 0;
		$scope.pageTotal = 0;
		$scope.SentBoxInit = 0;
		$scope.one = 1;
		$scope.inbox = [];
		$scope.inboxMemos = [];
		$scope.SentboxMemos = [];
		$scope.deleted = [];
		$scope.Sentbox = [];
		$scope.openMemo;
		$scope.reminderDate = new Date();
		$scope.openPopups = {};
		$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Operations Manager' || r.Name == 'System Administrator' || r.Name == 'Area Manager' || r.Name == 'Marketing' || r.Name == 'Partnership').length > 0 ? true : false;

		$scope.checkIt = function () {

			if (!$scope.EmailNotifications) {
				$scope.EmailNotifications = true;
			} else {
				$scope.EmailNotifications = false;
			}
			if ($scope.EmailNotifications)
				$scope.EmailNotificationStatus = "ON";
			else
				$scope.EmailNotificationStatus = "OFF";

			var data = {

				id: $rootScope.User.Id,
				status: $scope.EmailNotifications

			};
			//alert(JSON.stringify(data));
			MemoDetailService.setEmailNotifications(data);
			NotificationService.alert('Email Reminders', 'Email reminder notifications ' + $scope.EmailNotificationStatus, 'Note', {});
		};

		function randomDate(start, end) {
			return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
		};

		function randomBool() {
			return Math.random() > 0.5 ? true : false;
		};

		$scope.$on('RemindersUpdated', function () {
			$scope.LoadMemos();
		});


		$scope.init = function () {
			$scope.LoadMemos();
			if ($routeParams.id)
				getReminderById($routeParams.id);

			// Needs to be set with API call.
			//$scope.EmailNotifications = true;
			//$scope.EmailNotificationStatus = "ON";

			//$scope.EmailNotifications = false;
			//$scope.EmailNotificationStatus = "OFF";

			//alert(JSON.stringify($rootScope.User));

			var data = {

				id: $rootScope.User.Id,
				status: false

			};

			$scope.emailPromise = MemoDetailService.getEmailNotifications(data);

			$scope.emailPromise.then(function (output) {
				//alert(JSON.stringify(output));
				if (output == null) {
					$scope.EmailNotifications = false;
					$scope.EmailNotificationStatus = "OFF";
				}
				else {
					//alert(JSON.stringify(output.data.status));
					$scope.EmailNotifications = output.data.status;
					if ($scope.EmailNotifications)
						$scope.EmailNotificationStatus = "ON";
					else
						$scope.EmailNotificationStatus = "OFF";
				}
			}, function (error) {
				NotificationService.alert("Error!", "There has been an error.", "error", {});
			});

		};

		$scope.LoadMemos = function (dontOverrideMemo) {
			if ($rootScope.onLineFlag) {
				$scope.GetMemosPromise = MemoDetailService.getMemos($scope.mailBox, $scope.currentPage, $scope.pageSize);
				$scope.GetMemosPromise.then(function (output) {
					const memos = (output.data) ? output.data.memos : output;
					$scope.Sentbox = memos.filter(a => a.IsSender);
					$scope.inbox = memos.filter(a => (!a.IsSender));
					$scope.deleted = memos;
					if ($scope.mailBox == 'inbox') {
						$scope.totalInbox = output.data.totalMemos;
						$scope.inboxMemos = memos;
						$scope.inboxTotalPages = Math.ceil($scope.totalInbox / $scope.pageSize);
						$scope.pageTotal = $scope.inboxTotalPages;
					}
					else if ($scope.mailBox == 'sent') {
						$scope.SentBoxInit = 1;
						$scope.totalSentMemos = output.data.totalMemos;
						$scope.SentBoxMemos = memos;
						$scope.SentboxTotalPages = Math.ceil($scope.totalSentMemos / $scope.pageSize);
						$scope.pageTotal = $scope.SentboxTotalPages;
					}
					else if ($scope.mailBox == 'deleted') {
						$scope.totalDeleted = output.data.totalMemos;
						$scope.deletedMemos = memos;
						$scope.deletedTotalPages = Math.ceil($scope.totalDeleted / $scope.pageSize);
						$scope.pageTotal = $scope.deletedTotalPages;
					}
					$rootScope.$broadcast('appointmentRead', {});
					if (dontOverrideMemo == undefined)
						$scope.openMemo = $scope.displayedMemo;
					$scope.selected = false
					$scope.cleanHTML();
				}, function (err) {
					NotificationService.alert('Memo List Error', 'There was an error retrieving the Memo list, please try reloading the page again.', 'error', {});
				});
			} else {
				const sentMemo = IpadService.getMemosByType({memoType:'sent', pageNumber :$scope.currentPage-1, pageSize:$scope.pageSize });
				const inboxMemo = IpadService.getMemosByType({memoType:'inbox',   pageNumber :$scope.currentPage-1, pageSize:$scope.pageSize});
				const deletedMemo = IpadService.getMemosByType({memoType:'deleted', pageNumber :$scope.currentPage-1, pageSize:$scope.pageSize});

				inboxMemo.then(data => {
					//console.log(`inbox: ${memos}`);
					$scope.inboxMemos = data.memos;
					$scope.totalInbox = data.numberOfMemos||0;
					$scope.inboxTotalPages = data.numberOfPages;
					if ($scope.mailBox=='inbox'){ $scope.pageTotal = $scope.inboxTotalPages;}
				}, function(err){
					console.error(err);
				});
				sentMemo.then(data => {
					//console.log(`inbox: ${memos}`);
					$scope.SentBoxInit = 1;
					$scope.totalSentMemos =data.numberOfMemos||0;
					$scope.SentBoxMemos = data.memos;
					$scope.SentboxTotalPages = data.numberOfPages;
					if ($scope.mailBox =='sent'){ $scope.pageTotal = $scope.SentboxTotalPages;}
				}, function(err){
					console.error(err);
				});
				deletedMemo.then(data => {
					//console.log(`inbox: ${memos}`);
					$scope.totalDeleted = data.numberOfMemos||0;
					$scope.deletedMemos = data.memos;
					$scope.deletedTotalPages = data.numberOfPages;
					if ($scope.mailBox=='deleted'){ $scope.pageTotal = $scope.deletedTotalPages;}
				}, function(err){
					console.error(err);
				});
				$rootScope.$broadcast('appointmentRead', {});
				if (dontOverrideMemo == undefined)
					$scope.openMemo = $scope.displayedMemo;
				$scope.selected = false
				$scope.cleanHTML();
			}

			function IsSender(memo) {
				if (!memo) {
					return memo
				}
			};
		};

		$scope.cleanHTML = function () {
			var div = document.createElement("div");
			$scope.memoDescription = [];
			switch ($scope.mailBox) {
				case 'inbox':
					$scope.inboxMemos.forEach(function (memo) {
						var html = memo.MemoDetails;
						div.innerHTML = html;
						memo.memoDescription = div.innerText;
					});
					break;
				case 'sent':
					$scope.SentBoxMemos.forEach(function (memo) {
						var html = memo.MemoDetails;
						div.innerHTML = html;
						memo.memoDescription = div.innerText;
					});
					break;
				case 'deleted':
					$scope.deletedMemos.forEach(function (memo) {
						var html = memo.MemoDetails;
						div.innerHTML = html;
						memo.memoDescription = div.innerText;
					});
					break;
				default:

					break;
			}
		}

		function getReminderById(id) {
			$scope.gettingReminderByIdPromise = MemoDetailService.getMemoById(id);
			$scope.gettingReminderByIdPromise.then(data => {
				$scope.openMemo(data.data);
			}, err => {
				NotificationService.alert('Reminder Error', 'There was an error retrieving this reminder, please try reloading the page again.', 'error', {});
			});
		};

		function pager(List, pageMax, pageMin, pageLength) {
			var output = [];
			var totalLength = List.length;
			while (pageMin <= totalLength) {
				var page = List.slice(pageMin, pageMax);
				pageMin += pageLength;
				pageMax += pageLength;
				output.push(page);

			}
			return output;
		};

		$scope.nextPage = function () {
			if ($scope.currentPage < $scope.pageTotal) {
				$scope.currentPage += 1;
				$scope.LoadMemos();
				$scope.selected = false
			}
		};

		$scope.lastPage = function () {
			if ($scope.currentPage > 1) {
				$scope.currentPage -= 1
				$scope.LoadMemos();
				$scope.selected = false
			}

		};

		$scope.Reply = function (id) {
			var ra = [];
			ra.push({
				Name: $scope.openMemo.From,
				recipientId: $scope.openMemo.SenderId
			});
			var id = $scope.openMemo.SenderId;
			var subject = $scope.openMemo.Subject;
			var Recipients = ra;
			var Parent = $scope.openMemo.MemoId;
			var Replies = GenerateRepliesThread($scope.openMemo);
			if (!subject.startsWith('RE:'))
				subject = 'RE: ' + subject;
			$scope.newMessage(id, Recipients, Parent, subject, $scope.openMemo.Lead, $scope.openMemo.SentFromId, Replies);
		};

		$scope.Replyall = function () {
			var ra = [];
			ra.push({
				Name: $scope.openMemo.From,
				recipientId: $scope.openMemo.SenderId
			});
			ra = ra.concat($scope.openMemo.Recipients);
			var id = $scope.openMemo.SenderId;
			var subject = $scope.openMemo.Subject;
			var Recipients = ra;
			var Parent = $scope.openMemo.MemoId;
			var Replies = GenerateRepliesThread($scope.openMemo);
			if (!subject.startsWith('RE:'))
				subject = 'RE: ' + subject;
			$scope.newMessage(id, Recipients, Parent, subject, $scope.openMemo.Lead, $scope.openMemo.SentFromId, Replies);
		};

		function GenerateRepliesThread(om) {
			//setting new array to not populate old replies array when the user sends or closes the reply modal
			var replies = [];
			//fetch recipients from open memo
			var recipients = "";
			if (om.Recipients)
				recipients = om.Recipients.map(r => r.Name).join(", ");
			replies.push({
				From: om.From,
				Sent: om.Sent,
				Recipients: recipients,
				Subject: om.Subject,
				Message: om.MemoDetails, 
			})
			replies = replies.concat(om.Threads);
			return replies;
		}

		$scope.displayedMemo = function (item) {
			$scope.IsMemoOpen = true;
			$scope.changeOpenMemo(item);
			$scope.markAsRead(item);
		};

		$scope.closeMemo = function () {
			$scope.IsMemoOpen = undefined;
			$scope.LoadMemos();
		};

		$scope.changeOpenMemo = function (item) {
			$scope.openMemo = undefined;
			$scope.openMemo = item;
			$scope.FormattedRecipients = $scope.openMemo.Recipients.map(a => a.Name).join(', ');
			if ($scope.openMemo.ThreadId != '00000000-0000-0000-0000-000000000000')
				$scope.openMemo.OlderReplies = $scope.openMemo.Threads
		};

		$scope.toggleAll = function (value) {
			switch ($scope.mailBox) {
				case 'inbox':
					$scope.inboxMemos.forEach(function (current) {
						current.Selected = value;
					});
					break;
				case 'sent':
					$scope.Sentbox.forEach(function (current) {
						current.Selected = value;
					});
					break;
				case 'deleted':
					$scope.deleted.forEach(function (current) {
						current.Selected = value;
					});
					break;
				default:
					break;
			}
			$scope.inboxMemos.forEach(function (current) {
				current.Selected = value;
			});
		};

		$scope.actionChange = function (value) {
			var UpdatedMailIds = [];
			switch (value) {

				case 'Mark Read': //update 'Read' in MemoRecipient where Staff.Id matches current user and Memo.Id matches selected memos.
					if ($scope.mailBox == 'inbox') {
						$scope.inboxMemos.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					else if ($scope.mailBox == 'sent') {
						$scope.Sentbox.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					else if ($scope.mailBox == 'deleted') {

					}

					var data = {
						UpdatedMailIds: UpdatedMailIds,
						Action: 'Read'
					}
					$scope.ActionPromise = MemoDetailService.markMemoAsRead(data);
					break;

				case 'Mark Unread': // remove value of 'Read' in MemoRecipient where Staff.Id matches current User and Memo.Id matches selected memos.
					if ($scope.mailBox == 'inbox') {
						$scope.inboxMemos.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					else if ($scope.mailBox == 'sent') {
						$scope.Sentbox.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					else if ($scope.mailBox == 'deleted') {
						$scope.deletedMemos.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					var data = {
						UpdatedMailIds: UpdatedMailIds,
						Action: 'Unread'
					}
					$scope.ActionPromise = MemoDetailService.markMemoAsRead(data);
					break;

				case 'Move': // move a memo out of delete back to its original location.
					$scope.deletedMemos.forEach(function (current) {
						if (current.Selected == true) {
							UpdatedMailIds.push(current.MemoId);
						}
					});
					var data = {
						MemoIds: UpdatedMailIds,
						State: 'MoveOutOfDelete'
					}
					$scope.ActionPromise = MemoDetailService.deleteMemo(data);
					break;

				case 'Delete':  // update memo to be deleted.
					if ($scope.mailBox == 'inbox') {
						$scope.inboxMemos.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					else if ($scope.mailBox == 'sent') {
						$scope.Sentbox.forEach(function (current) {
							if (current.Selected == true) {
								UpdatedMailIds.push(current.MemoId);
							}
						});
					}
					var data = {
						MemoIds: UpdatedMailIds,
						State: 'MoveToDelete'
					}
					$scope.ActionPromise = MemoDetailService.deleteMemo(data);
					break;

				default:
					NotificationService.alert('Action Alert', 'Please select a valid action', 'error', {});
					break;
			}
			if ($scope.ActionPromise) {
				$scope.ActionPromise.then(function (output) {
					NotificationService.alert('Memos Updated', 'Memos have sucessfully been updated.', 'success', {});
					$scope.selected = false;
					$scope.init();
					$rootScope.$broadcast('appointmentRead', {});

				});
			}
		};

		$scope.markAsRead = function (item) {
			var UpdatedMailIds = [];
			UpdatedMailIds.push(item.MemoId)
			var data = {
				UpdatedMailIds: UpdatedMailIds,
				Action: 'Read'
			}
			$scope.markAsReadPromise = MemoDetailService.markMemoAsRead(data);
			$scope.markAsReadPromise.then(function (output) {
				$scope.LoadMemos(output);
			});
		};

		$scope.newMessage = function (id, Recipients, Parent, subject, leadId,sentFromId,replies) {
			var sendList = [];
			if (Recipients != undefined)
				Recipients.forEach(function (Name) {
					if (Name.recipientId != $rootScope.User.Id)
					sendList.push(Name);
				})
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'newMessageModal.html',
				controller: 'NewMessageController',
				resolve: {
					ParentMemo: function () {
						return Parent;
					},
					Recipients: function () {
						return sendList;
					},
					Id: function () {
						return id;
					},
					LeadId: function () {
						return leadId;
					},
					Subject: function () {
						return subject;
					},
					SentFromId: function () {
						return sentFromId;
					},
					Replies: function () {
						return replies;
					}
				}
			});
		};

		$scope.remindMe = function (id) {
			$scope.memoRemindMePromise = MemoDetailService.remindMe({ MemoId: id, RemindOn: $scope.reminderDate });
			$scope.memoRemindMePromise.then(function (output) {
				NotificationService.alert("Reminder Set", "You will be reminded.", "success", {});
				$scope.closeMemo();
			}, function (error) {
				NotificationService.alert("Error!", "There has been an error.", "error", {});
			});
		};

		$scope.goToLead = function (id) {
			var roles = $rootScope.User.Roles;
			var GoToLead = roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Manager' || r.Name == 'Area Manager' || r.Name == 'Sales Person/Fitter').length > 0 ? true : false;
			var GoToSales = roles.filter(r => r.Name == 'Fitter' || r.Name == 'Field Manager').length > 0 ? true : false;

			if (GoToLead)
				$location.url('/lead-details/' + id);
			else if (GoToSales)
				$location.url('/sales-order/' + id);
			else {
				$location.url('/enquiry-details/' + id);
			}
		}

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		if ($location.path() == '/inbox/create-new') {
			$scope.newMessage();
		};

		$scope.GetInbox = function () {
			$scope.mailBox = 'inbox';
			$scope.currentPage = 1;
			$scope.LoadMemos();
		};

		$scope.GetSentMemos = function () {
			$scope.mailBox = 'sent';
			$scope.currentPage = 1;
			$scope.LoadMemos();
		};

		$scope.GetDeletedMemos = function () {
			$scope.mailBox = 'deleted';
			$scope.currentPage = 1;
			$scope.LoadMemos();
		};

		$scope.setDisplayAmount = function (displayAmount) {
			$scope.pageSize = displayAmount;
			$scope.currentPage = 1;
			$scope.LoadMemos();
		}

		$scope.goToPage = function (event) {
			if (event.keyCode == 13) {
				var requestedPage = document.getElementById('pageRequestBox').value;
				if (requestedPage == null || requestedPage > $scope.pageTotal || requestedPage < 1) {
					NotificationService.alert("Invalid Input", "Please make sure there is a number inside the text box that is between the values of 1 and " + $scope.totalPages + ".", "error", {});
				}
				else {
					$scope.currentPage = requestedPage;
					$scope.LoadMemos();
				}
			}

		}
	}])
